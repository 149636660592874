export const AccordionItem = ({
	title,
	content,
	index,
	currentSelected,
	setCurrentSelected,
	totalFaqs,
}) => {
	const handleOnClick = () => {
		if (currentSelected === index) {
			setCurrentSelected(0);
		} else {
			setCurrentSelected(index);
		}
	};

	return (
		<div
			className={`${index === totalFaqs ? "border-b-0" : "border-b-2"} border-b-border-gray pb-2 mb-12`}
		>
			<div className="flex mb-2 cursor-pointer">
				<span
					className={`transition-all ease-in duration-700 text-secondary text-base mr-2 ${
						currentSelected === index ? "hidden" : "block"
					}`}
					onClick={handleOnClick}
				>
					+
				</span>
				<h3
					className={`transition-all ease-in duration-150 text-base ${
						currentSelected === index ? "font-black" : "font-bold"
					}`}
					onClick={handleOnClick}
				>
					{title}
				</h3>
			</div>
			<div
				className={`transition-all ease-in duration-700 flex mb-4 ${
					currentSelected === index ? "h-auto" : "h-0"
				} overflow-hidden`}
			>
				<span className="text-secondary flex items-center px-4 h-10 border-r-accent border-r-2 p-4 mr-4">
					—
				</span>
				<p>{content}</p>
			</div>
		</div>
	);
};
