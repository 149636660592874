import { useEffect, useState } from "react";
import { setCurrentCountryLocal, getCurrentCountry } from 'utils/currentCountry';
import { AppContext } from "./AppContext";

export const AppState = ({ children }) => {

	const [countryData, setCountryData] = useState({});

  const setCurrentCountry = (country) => {
    const countryObj = {
			code: country.country_code,
			text: country.name,
			flag: country.flag_url,
			currency: country.currency_code,
		}
		setCurrentCountryLocal(countryObj);
		setCountryData(countryObj);
  }

	useEffect(() => {
		const countryReference = getCurrentCountry()

		if (countryReference) {
			setCountryData(countryReference);
		}
	}, []);

	return <AppContext.Provider value={{ countryData, setCurrentCountry}}>{children}</AppContext.Provider>;
};
