export const DownloadOptions = () => {
	return (
		<div className="flex w-full flex-col items-center md:flex-row place-content-center">
			<a
				href="https://play.google.com/store/apps/details?id=com.devstack.efektivos"
				target="_blank"
				rel="noreferrer"
				className=" block mr-0 mb-8 md:mb-0 md:mr-4 rounded-lg overflow-visible"
			>
				<img
					src={`https://lh3.googleusercontent.com/cjsqrWQKJQp9RFO7-hJ9AfpKzbUb_Y84vXfjlP0iRHBvladwAfXih984olktDhPnFqyZ0nu9A5jvFwOEQPXzv7hr3ce3QVsLN8kQ2Ao=s0`}
					alt="Google play download"
					className="h-full "
				/>
			</a>
			{/* <a
				href="https://www.apple.com/la/app-store/"
				target="_blank"
				rel="noreferrer"
				className="w-[47%] md:w-1/4 block rounded-lg overflow-visible"
			>
				<img
					src={`${process.env.PUBLIC_URL}/assets/app_store.png`}
					alt="App store download"
					className="h-full "
				/>
			</a> */}
		</div>
	);
};
