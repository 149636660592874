export const setCurrentCountryLocal = (countryObj) => {
  if (countryObj) {
    localStorage.setItem("persist:efektivos_current_country", JSON.stringify(countryObj))
  }
}
export const getCurrentCountry = () => {
  const reference = localStorage.getItem("persist:efektivos_current_country")

  if (reference) {
    const countryObj = JSON.parse(reference)
    return countryObj
  }
  return { code: "GT", text: "Guatemala", flag: `${process.env.PUBLIC_URL}/assets/default_flag.png`, currency: 'Q' }
}