export const navRoutes = (intl) => {
	return [
		{
			label: intl.formatMessage({ id: "APP.NAV.HOME.LABEL" }),
			path: paths.home,
		},
		{
			label: intl.formatMessage({ id: "APP.NAV.CREDIT.CALCULATOR.LABEL" }),
			path: paths.calculator,
		},
		{ label: intl.formatMessage({ id: "APP.NAV.FQ.LABEL" }), path: paths.fq },
		{
			label: intl.formatMessage({ id: "APP.NAV.DOWNLOADS.LABEL" }),
			path: paths.downloads,
		},
		{
			label: intl.formatMessage({ id: "APP.NAV.HOW.WORKS.LABEL" }),
			path: paths.how_works,
		},
		{
			label: intl.formatMessage({ id: "APP.NAV.CONTACT.LABEL" }),
			path: paths.contact,
		},
		{
			label: intl.formatMessage({ id: "APP.NAV.DELETE.ACCOUNT.LABEL" }),
			path: paths.sign_out,
		},
	];
};
export const routes = () => [
	{ path: paths.home },
	{ path: paths.calculator },
	{ path: paths.fq },
	{ path: paths.contact },
	{ path: paths.downloads },
	{ path: paths.how_works },
];

export const paths = {
	home: "#home",
	calculator: "#calculator",
	fq: "#faqs",
	contact: "#contact",
	downloads: "#downloads",
	how_works: "#how-works",
	sign_out: "/delete-account"
};
// export const paths = {
// 	home: "/",
// 	calculator: "/credit-calculator",
// 	fq: "/fq",
// 	contact: "/contact",
// 	downloads: "/downloads",
// 	"how-works": "/how-works",
// };
