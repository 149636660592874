import { useIntl } from "react-intl";

export const Privacy = () => {
	const intl = useIntl();

	return (
		<main className="px-6 md:px-12">
			<h1 className="font-bold text-2xl text-center mb-4 mt-8">
				{intl.formatMessage({ id: "APP.TITLE" })}
			</h1>
			<section>
				<h2 className="text-center text-lg mb-4">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.TITLE" })}
				</h2>
				<p className="mb-3 text-sm text-justify text-justify leading-6">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.PARAGRAPH.1" })}
				</p>
				{/* <p className="mb-3 text-sm text-justify">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.PARAGRAPH.2" })}
				</p>
				<p className="mb-3 text-sm text-justify">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.PARAGRAPH.3" })}
				</p> */}
			</section>
			<section>
				<h2 className="text-center my-8 font-bold">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.SECTION.2.TITLE" })}
				</h2>
				<p className="text-sm text-justify leading-6">
					{intl.formatMessage({
						id: "APP.PRIVACY.POLICY.SECTION.2.PARAGRAPH.1",
					})}
				</p>
			</section>
			<section>
				<h2 className="text-center my-8 font-bold">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.SECTION.3.TITLE" })}
				</h2>
				<p
					dangerouslySetInnerHTML={{
						__html: intl.formatMessage({
							id: "APP.PRIVACY.POLICY.SECTION.3.PARAGRAPH.1",
						}),
					}}
					className="text-sm text-justify leading-6 mb-3"
				></p>
				<p className="text-sm text-justify leading-6 mb-3">
					{intl.formatMessage({
						id: "APP.PRIVACY.POLICY.SECTION.3.PARAGRAPH.2",
					})}
				</p>
			</section>
			<section>
				<h2 className="text-center my-8 font-bold">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.SECTION.4.TITLE" })}
				</h2>
				<p className="text-sm text-justify leading-6 mb-3">
					{intl.formatMessage({
						id: "APP.PRIVACY.POLICY.SECTION.4.PARAGRAPH.1",
					})}
				</p>
				<p className="text-sm text-justify leading-6 mb-3">
					{intl.formatMessage({
						id: "APP.PRIVACY.POLICY.SECTION.4.PARAGRAPH.2",
					})}
				</p>
			</section>
			<section>
				<h2 className="text-center my-8 font-bold">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.SECTION.5.TITLE" })}
				</h2>
				<p className="text-sm text-justify leading-6 mb-3">
					{intl.formatMessage({
						id: "APP.PRIVACY.POLICY.SECTION.5.PARAGRAPH.1",
					})}
				</p>
			</section>
			<section>
				<h2 className="text-center my-8 font-bold">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.SECTION.6.TITLE" })}
				</h2>
				<p className="text-sm text-justify leading-6 mb-3">
					{intl.formatMessage({
						id: "APP.PRIVACY.POLICY.SECTION.6.PARAGRAPH.1",
					})}
				</p>
			</section>
			<section>
				<h2 className="text-center my-8 font-bold">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.SECTION.7.TITLE" })}
				</h2>

				<p
					className="text-sm text-justify leading-6 mb-3"
					dangerouslySetInnerHTML={{
						__html: intl.formatMessage({
							id: "APP.PRIVACY.POLICY.SECTION.7.PARAGRAPH.1",
						}),
					}}
				></p>
			</section>
			<section>
				<h2 className="text-center my-8 font-bold">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.SECTION.8.TITLE" })}
				</h2>
				<p className="text-sm text-justify leading-6 mb-3">
					{intl.formatMessage({
						id: "APP.PRIVACY.POLICY.SECTION.8.PARAGRAPH.1",
					})}
				</p>
			</section>
			<section className='mb-8'>
				<h2 className="text-center my-8 font-bold">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.SECTION.9.TITLE" })}
				</h2>
				<p className="text-sm text-justify leading-6 mb-3">
					{intl.formatMessage({
						id: "APP.PRIVACY.POLICY.SECTION.9.PARAGRAPH.1",
					})}
				</p>
				</section>
			<section className='mb-8'>
				<h2 className="text-center my-8 font-bold">
					{intl.formatMessage({ id: "APP.PRIVACY.POLICY.SECTION.10.TITLE" })}
				</h2>
				<p className="text-sm text-justify leading-6 mb-3">
					{intl.formatMessage({
						id: "APP.PRIVACY.POLICY.SECTION.9.PARAGRAPH.2",
					})}
				</p>
			</section>
			<section>
				{/* <h2 className="text-center my-8 font-bold">
					{intl.formatMessage({
						id: "APP.PRIVACY.POLICY.CONTACT.SECTION.TITLE",
					})}
				</h2>
				<p className="text-sm text-justify leading-6 mb-3">
					{intl.formatMessage({
						id: "APP.PRIVACY.POLICY.CONTACT.SECTION.COMPANY.NAME",
					})}
				</p>
				<p className="text-sm text-justify leading-6 mb-3">
					<span className="mr-2">
						{intl.formatMessage({
							id: "APP.PRIVACY.POLICY.CONTACT.SECTION.COMPANY.EMAIL",
						})}
					</span>
					<a
						className="text-primary underline"
						href="mailto:info@efektivos.com"
					>
						info@efektivos.com
					</a>
				</p> */}
				{/* <p
					dangerouslySetInnerHTML={{
						__html: intl.formatMessage({
							id: "APP.PRIVACY.POLICY.LAST.UPDATE",
						}),
					}}
					className="text-sm text-justify leading-6 mb-3"
				></p> */}
			</section>
		</main>
	);
};
