import { useContext } from "react";

import { paths } from "routes/routes";
import { AppContext } from "context/AppContext";
import { useFetch } from "hooks/useFetch";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Container } from "components/Container/Container";
import { CreditCalculator } from "components/CreditCalculator/CreditCalculator";
import { DownloadsSection } from "components/DownloadsSection/DownloadsSection";
import { Footer } from "components/Footer/Footer";
import { Fq } from "components/Fq/Fq";
import { HeroBanner } from "components/HeroBanner/HeroBanner";
import { HowWorks } from "components/HowWorks/HowWorks";
import { Navbar } from "components/Navbar/Navbar";
import { WhereCanIPay } from "components/WhereCanIPay/WhereCanIPay";
import 'animate.css';


const firebaseConfig = {
  apiKey: "AIzaSyCEE4bf1lLeVCSZjg5Eah6mCAaZrDKTOOk",
  authDomain: "efektivos-dev-a03c3.firebaseapp.com",
  projectId: "efektivos-dev-a03c3",
  storageBucket: "efektivos-dev-a03c3.appspot.com",
  messagingSenderId: "384883924229",
  appId: "1:384883924229:web:4d6a200da4df5f9ed82b6c",
  measurementId: "G-TQW3NYPRSB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const Home = () => {
	const { countryData } = useContext(AppContext);
	
	const state = useFetch(
		"https://us-central1-efektivos-dev-a03c3.cloudfunctions.net/api/v1/landing-page",
		countryData
	);

	const { faqs = [], credit_types = [], countries = [] } = state.data || {};
	const { loading, error } = state;

	return (
		<>
			<Navbar countries={countries} />
			<div id={paths.home.slice(1)} className="h-4 mb-8 xl:mb-14 w-full"></div>
			<HeroBanner />
			<div className="relative z-10 bg-white">
				<Container className="pt-2">
					<div id={paths.calculator.slice(1)} className="h-4 my-16 w-full"></div>
					<CreditCalculator credit_types={credit_types} isLoading={loading} error={error}/>
					<div id={paths.fq.slice(1)} className="h-0 my-16 w-full"></div>
					<Fq faqs={faqs} isLoading={loading} error={error} />
					<div id={paths.downloads.slice(1)} className="w-full my-16"></div>
					<DownloadsSection />
					<div id={paths.how_works.slice(1)} className="w-full"></div>
					<HowWorks />
					<div className=" w-full"></div>
					<WhereCanIPay />
					<div id={paths.contact.slice(1)} className="w-full"
					></div>
					{/* <Contact /> */}
					<div className="w-full"></div>
				</Container>
				<Footer />
			</div>
		</>
	);
};
