import { FormattedMessage } from "react-intl";
import { useInView } from "react-intersection-observer";

import { DownloadOptions } from "components/DownloadOptions/DownloadOptions";

export const DownloadsSection = () => {
	const { ref, inView } = useInView();

	return (
		<section className="flex flex-wrap md:items-center md:py-16 px-4 pb-5">
			<div className="w-full md:w-1/2 mb-10 md:mb-0 ">
				<h2 className="text-center md:text-center text-4xl leading-10 md:text-5xl md:leading-20 font-black mb-12 md:mb-8">
					<FormattedMessage id="APP.DOWNLOADS.SECTION.TITLE" />
				</h2>
				<p className="mb-8 text-center md:text-center">
					<FormattedMessage id="APP.DOWNLOADS.SECTION.DESCRIPTION" />
				</p>
				<DownloadOptions />
			</div>
			<div className="w-full md:w-1/2 flex justify-center md:justify-end">
				<img
					ref={ref}
					src={`${process.env.PUBLIC_URL}/assets/app_screenshots.png`}
					alt="Capturas de pantalla de la aplicación"
					className={`w-full object-cover md:w-[80%] lg:w-[90%] ${
						inView ? "animate__animated animate__fadeInRight" : ""
					}`}
				/>
			</div>
		</section>
	);
};
