import { AppState } from 'context/AppState';
import { AppRoutes } from "routes/AppRoutes";
import { I18nProvider } from "utils/i18n";

function App() {
	return (
		<AppState>
			<I18nProvider>
				<AppRoutes />
			</I18nProvider>
		</AppState>
	);
}

export default App;
