import { useState } from "react";
import { AccordionItem } from "./AccordionItem";

export const Accordion = ({ faqs }) => {
	const [currentSelected, setCurrentSelected] = useState(0);
	const faqsList = faqs || []

	return (
		<div>
			{faqsList.map((faq, i) => (
				<AccordionItem
					title={faq.title}
					content={faq.description}
					key={faq.uid}
					index={i + 1}
					currentSelected={currentSelected}
					setCurrentSelected={setCurrentSelected}
					totalFaqs={faqs.length}
				/>
			))}
		</div>
	);
};
