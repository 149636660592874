import {IntlProvider} from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/es';
import { useLang } from './Contexti18n';

import enMessages from './messages/en';
import esMessages from './messages/es';

const allMessages = {
  en: enMessages,
  es: esMessages,
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
