import { Container } from "components/Container/Container";
import { SVGComponent } from "components/SVGComponent/SVGComponent";
import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';

export const Footer = () => {
	return (
		<footer className="bg-text-title py-[72px]">
			<Container className="text-text-footer-content flex justify-between items-start flex-wrap">
				<div className="w-full md:w-[45%] lg:w-[20%] mb-14 lg:mb-0 text-center md:text-left">
					<img
						className="w-[80%] object-cover mb-6 mx-auto md:mx-0"
						src={`${process.env.PUBLIC_URL}/assets/efektivos_logo_white.png`}
						alt="Efektivos"
					/>


				</div>
				<div className="w-full md:w-[45%] lg:w-[28%] xl:[30%] mb-14 lg:mb-0 text-center md:text-left">
				<h3 className="text-2xl text-white font-bold mb-6">
				Políticas
					</h3>
					<p className='hover:text-white font-bold'>
						<Link to={'/privacy'} target='_blank'>
							<FormattedMessage id='APP.PRIVACY.POLICY.TITLE' />
						</Link>
					</p>
				</div>
				<div className="w-full md:w-[45%] lg:w-[20%] mb-14 lg:mb-0 text-center md:text-left">
					<h3 className="text-2xl text-white font-bold mb-6">
						<FormattedMessage id="APP.NAV.CONTACT.LABEL" />
					</h3>
					<p className="text-ellipsis overflow-hidden">
						<a
							className="hover:text-white font-bold"
							href="mailto:info@efektivos.com"
						>
							info@efektivos.com
						</a>
					</p>
				</div>
				<div className="w-full md:w-[45%] lg:w-[20%] flex justify-around lg:justify-end xl:justify-center mb-14 lg:mb-0">
					<div className="rounded-full bg-secondary svg-icon p-3 mr-4 3xl:mr-10 transition ease-in-out delay-150 hover:scale-110 duration-300">
						<a
							href="https://www.facebook.com/Efektivos-112507398124013"
							target="_blank"
							rel="noreferrer"
						>
							<SVGComponent url="/assets/facebook.svg" />
						</a>
					</div>
		
					<div className="rounded-full bg-secondary svg-icon p-3 transition ease-in-out delay-150 hover:scale-110 duration-300">
						<a href="https://www.instagram.com/efektivos/?hl=es" target="_blank" rel="noreferrer">
							<SVGComponent url="/assets/instagram.svg" />
						</a>
					</div>
				</div>
			</Container>
		</footer>
	);
};
