import { DownloadOptions } from "components/DownloadOptions/DownloadOptions";
import { Steps } from "components/Steps/Steps";
import { FormattedMessage, useIntl } from "react-intl";

export const HowWorks = () => {
	const intl = useIntl();

	return (
		<section>
			<h2 className="text-center md:text-center text-4xl leading-10 md:text-5xl md:leading-20 font-black ">
				<FormattedMessage id="APP.NAV.HOW.WORKS.LABEL" />
			</h2>
			<p className="mb-20 text-center md:text-center">
				<FormattedMessage id="APP.HOW.WORKS.SECTION.DESCRIPTION" />
			</p>
			<div className="container-works px-10 ">
				<div className="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-0 md:w-[50%] w-full place-items-center  ">
					<Steps
						number={1}
						stepTitleKey="APP.HOW.WORKS.SECTION.STEP.ONE.TITLE"
						stepDescOneKey="APP.HOW.WORKS.SECTION.STEP.ONE.DESCRIPTION.1"
						stepDescTwoKey="APP.HOW.WORKS.SECTION.STEP.ONE.DESCRIPTION.2"
					/>
					<Steps
						number={2}
						stepTitleKey="APP.HOW.WORKS.SECTION.STEP.TWO.TITLE"
						stepDescOneKey="APP.HOW.WORKS.SECTION.STEP.TWO.DESCRIPTION.1"
						stepDescTwoKey="APP.HOW.WORKS.SECTION.STEP.TWO.DESCRIPTION.2"
				
					/>
					<Steps
						number={3}
						stepTitleKey="APP.HOW.WORKS.SECTION.STEP.SMS.TITLE"
						stepDescOneKey="APP.HOW.WORKS.SECTION.STEP.SMS.DESCRIPTION.1"
						stepDescTwoKey="APP.HOW.WORKS.SECTION.STEP.ONE.DESCRIPTION.2"
					
					/>
					<Steps
						number={4}
						stepTitleKey="APP.HOW.WORKS.SECTION.STEP.THREE.TITLE"
						stepDescOneKey="APP.HOW.WORKS.SECTION.STEP.THREE.DESCRIPTION.1"
						stepDescTwoKey="APP.HOW.WORKS.SECTION.STEP.THREE.DESCRIPTION.2"
					
					/>
					<Steps
						number={5}
						stepTitleKey="APP.HOW.WORKS.SECTION.STEP.FOUR.TITLE"
						stepDescOneKey="APP.HOW.WORKS.SECTION.STEP.FOUR.DESCRIPTION.1"
						stepDescTwoKey="APP.HOW.WORKS.SECTION.STEP.FOUR.DESCRIPTION.2"
				
					/>
					<Steps
						number={6}
						stepTitleKey="APP.HOW.WORKS.SECTION.STEP.FIVE.TITLE"
						stepDescOneKey="APP.HOW.WORKS.SECTION.STEP.FIVE.DESCRIPTION.1"
						stepDescTwoKey="APP.HOW.WORKS.SECTION.STEP.FIVE.DESCRIPTION.2"
					
					/>
					<Steps
						number={7}
						stepTitleKey="APP.HOW.WORKS.SECTION.STEP.SIX.TITLE"
						stepDescOneKey="APP.HOW.WORKS.SECTION.STEP.SIX.DESCRIPTION.1"
						stepDescTwoKey="APP.HOW.WORKS.SECTION.STEP.SIX.DESCRIPTION.2"
				
					/>
				</div>
			</div>

		</section>
	);
};
