import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

export const SVGComponent = ({ url, className = '' }) => {
  return (
    <SVG src={`${process.env.PUBLIC_URL}${url}`} className={className}/>
  )
}

SVGComponent.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string
}
