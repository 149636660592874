import { SVGComponent } from "components/SVGComponent/SVGComponent";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { navRoutes } from "routes/routes";

export const MobileMenu = ({
	countriesList,
	flag,
	text,
	handleOnClick,
	handleOnSelectChange,
}) => {
	const intl = useIntl();
	const { hash } = useLocation();
	const [isOpen, setIsOpen] = useState(false);
	const [changeIcon, setChangeIcon] = useState(false);

	useEffect(() => {
		const html = document.querySelector("html");
		const body = document.querySelector("body");

		if (isOpen) {
			setTimeout(() => {
				html.classList.add("block-scroll");
				body.classList.add("block-scroll");
				setChangeIcon(isOpen);
			}, 300);
		} else {
			setTimeout(() => {
				html.classList.remove("block-scroll");
				body.classList.remove("block-scroll");
				setChangeIcon(isOpen);
			}, 350);
		}
	}, [isOpen]);

	return (
		<div className="block xl:hidden mr-4 relative">
			<span
				className="svg-icon svg-3x cursor-pointer"
				onClick={() => setIsOpen(!isOpen)}
			>
				{changeIcon ? (
					<SVGComponent url="/assets/close.svg" />
				) : (
					<SVGComponent url="/assets/burger.svg" />
				)}
			</span>
			<div
				className={`m-h-screen w-screen bg-white z-30 fixed ${
					isOpen ? "left-0" : "left-full"
				} bottom-0 top-[62px] flex flex-col transition-[left] ease-in-out delay-300 duration-500 overflow-y-scroll`}
			>
				<div className="flex flex-col h-[100%] justify-center">
					<ul
						className={`flex flex-col justify-center items-center mb-10 ${
							isOpen ? "opacity-100" : "opacity-0"
						} transition-all ease-in-out delay-700 duration-500`}
					>
						{navRoutes(intl).map((route) => (
							<li key={route.path} onClick={() => setIsOpen(!isOpen)}>
								<a
									href={route.path}
									className={`py-4 block ${
										hash === route.path ? "font-bold" : ""
									} transition ease-in-out delay-400 hover:font-bold`}
								>
									{route.label}
								</a>
							</li>
						))}
					</ul>
					<div
						className={`w-full flex justify-center items-center ${
							isOpen ? "opacity-100" : "opacity-0"
						} transition-all ease-in-out delay-700 duration-500`}
					>
						<div className="rounded-full bg-indigo-900 svg-icon p-2 mr-3 transition ease-in-out delay-150 hover:scale-110 duration-300">
							<a
						href="https://www.facebook.com/Efektivos-112507398124013"
						target="_blank"
								rel="noreferrer"
							>
								<SVGComponent url="/assets/facebook.svg" />
							</a>
						</div>
					
						<div className="rounded-full bg-indigo-900 svg-icon p-2 mr-3 transition ease-in-out delay-150 hover:scale-110 duration-300">
						<a href="https://www.instagram.com/efektivos/?hl=es" target="_blank" rel="noreferrer">
								<SVGComponent url="/assets/instagram.svg" />
							</a>
						</div>
						<div
							className="w-[35px] h-[35px] relative"
							id="flag-icon-container"
						>
							<img
								className="w-full h-full rounded-full object-cover cursor-pointer"
								src={flag}
								alt={text}
								onClick={() => handleOnClick("toggle-mb-country")}
								id="flag-icon"
							/>
							<div
								id="toggle-mb-country"
								className={`bg-white shadow-lg absolute flex flex-col px-2 rounded-br-xl rounded-bl-xl top-full -left-1.5 overflow-hidden h-0`}
							>
								{countriesList.map((country) => (
									<div className="w-[35px] h-[35px] my-2" key={country.uid}>
										<img
											src={country.flag_url}
											alt={country.name}
											className="w-full h-full rounded-full object-cover cursor-pointer"
											onClick={() => {
												setIsOpen(!isOpen);
												handleOnSelectChange(country.country_code);
											}}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
