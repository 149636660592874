import { useCallback, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Home } from "pages/Home";
import { Privacy } from 'pages/Privacy';
import { DeleteAccount } from "pages/DeleteAccount"

export const AppRoutes = () => {
	const clickHandler = useCallback((event) => {
		if (
			event.target.id !== "flag-icon" &&
			event.target.id !== "flag-icon-container"
		) {
			const element = document.querySelector("#toggle-country");
			const element2 = document.querySelector("#toggle-mb-country");
			if (element) {
				if (element.classList.contains("h-auto") || element2.classList.contains("h-auto")) {
					element.classList.remove("h-auto");
					element.classList.add("h-0");
					element2.classList.remove("h-auto");
					element2.classList.add("h-0");
				}
			}
		}
	}, []);

	useEffect(() => {
		document.addEventListener("click", clickHandler);

		return () => {
			document.removeEventListener("click", clickHandler);
		};
	}, [clickHandler]);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/privacy" element={<Privacy />} />
				<Route path="/delete-account" element={<DeleteAccount />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</BrowserRouter>
	);
};
