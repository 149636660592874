import { useContext, useEffect, useState } from "react";
import { FormattedMessage, FormattedPlural, useIntl } from "react-intl";
import { AppContext } from 'context/AppContext';

import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { Spinner } from "components/spinner/Spinner";
import { SVGComponent } from "components/SVGComponent/SVGComponent";
import { setCurrencyFormat } from "utils/stringsUtils";

export const CreditCalculator = ({ credit_types, isLoading, error }) => {
	const intl = useIntl();
	const [options, setOptions] = useState([]);
	const [allOptions, setAllOptions] = useState([]);
	const [optionSelected, setOptionSelected] = useState({});
	const [fee, setFee] = useState(0);
	const [amount, setAmount] = useState(0);

	const { countryData } = useContext(AppContext);
	const { currency } = countryData

	useEffect(() => {
		if (credit_types.length > 0) {
			const optionsForSelect = credit_types
			.filter(credit => credit.payments_quantity === 1)
			.map((credit) => {
				const quantity =
					credit.payments_quantity > 1
						? "APP.CREDIT.CALCULATOR.PAYMENTS.LABEL"
						: "APP.CREDIT.CALCULATOR.PAYMENT.LABEL";
				return {
					value: credit.uid,
					label: `${credit.period_text} - ${credit.payments_quantity
						} ${intl.formatMessage({ id: quantity })}`,
				};
			});
			setOptions([optionsForSelect[0]]);
			setOptionSelected(credit_types[0]);
			setAllOptions(credit_types);
		}
	}, [credit_types, intl]);

	const handleOnSubmit = (e) => {
		e.preventDefault();
	};

	const handleOnSelectChange = (e) => {
		const { value } = e.target;
		const newOptionSelected = allOptions.find((credit) => credit.uid === value);
		let newFee = 0;

		if (newOptionSelected) {
			setOptionSelected(newOptionSelected);

			if (amount > 0) {
				const interestRate = newOptionSelected.interest_rate / 100;
				const newInterest = amount * interestRate;
				newFee =
					(amount + newInterest) / Number(newOptionSelected.payments_quantity);
				setFee(newFee);
			}
		}
	};

	const handleAmountValueChange = (e) => {
		const { value } = e.target;
		const amountValue = Number(value);
		let newFee = 0;

		if (amountValue > 0) {
			const interestRate = optionSelected.interest_rate / 100;
			const newInterest = amountValue * interestRate;
			newFee =
				(amountValue + newInterest) / Number(optionSelected.payments_quantity);
		}

		setAmount(Number(value));
		setFee(newFee);
	};

	return (
		<section>

			<div className="flex flex-col lg:flex-row justify-center md:pb-16">

				<div className="w-full lg:w-[45%]">
				<h3 className="mb-12 text-3xl text-text-primary font-bold text-center md:text-6xl">
						<FormattedMessage id="APP.CREDIT.CALCULATOR.SECTION.TITLE.2" />

					</h3>
					<p
						dangerouslySetInnerHTML={{
							__html: intl.formatMessage({
								id: "APP.CREDIT.CALCULATOR.SECTION.DESCRIPTION",
							}),
						}}
						className="mb-6 text-center md:text-left"
					></p>
				
					<form onSubmit={handleOnSubmit}>
						{!error && !isLoading && credit_types.length > 0 && (
							<div className="flex border-4 border-secondary rounded-2xl overflow-hidden bg-white items-center py-4 px-6 shadow-2xl">
								<span className="text-3xl mr-2">{currency}</span>
								<input
									type="number"
									className="w-full text-3xl outline-none"
									placeholder="2000"
									min={0}
									defaultValue={amount}
									onChange={handleAmountValueChange}
								/>
								<span className="svg-icon svg-2x rounded-full bg-black/30 p-3 cursor-pointer">
									<SVGComponent url="/assets/arrow-right.svg" />
								</span>
							</div>
						)}

						{isLoading ? (
							<div className="bg-white w-[95%] m-auto py-7 px-6 rounded-bl-2xl rounded-br-2xl shadow-xl flex justify-center items-center">
								<Spinner />
							</div>
						) : (
							<>
								{error ? (
									<div className="bg-white w-[95%] m-auto py-7 px-6 rounded-bl-2xl rounded-br-2xl shadow-xl">
										<ErrorMessage
											image="warning.svg"
											descriptionKey="APP.GENERAL.ERROR.DESCRIPTION"
										/>
									</div>
								) : (
									<>
										{credit_types.length > 0 ? (
											<div className="bg-primary-gray w-[95%] m-auto py-7 px-6 rounded-bl-2xl rounded-br-2xl shadow-xl">
												<div className="flex flex-col md:flex-row md:justify-between md:mb-12 flex-wrap">
													<div className="flex items-center w-full justify-start mb-8 md:mb-0">
														<span className="svg-icon svg-2x mr-5">
															<SVGComponent url="/assets/calendar.svg" />
														</span>
														<div className="text-xl mr-2 flex flex-wrap w-full">
															<p className="mr-2">Período:</p>
															<select
																className="bg-select-bg text-select-color outline-none text-lg rounded-xl font-semibold cursor-pointer text-base"
																name="period"
																value={optionSelected.uid}
																onChange={handleOnSelectChange}
																options={options}
																data-kt-menu-dismiss="false"
															>
																{options.map((option) => (
																	<option
																		value={option.value}
																		key={option.value}
																	>
																		{option.label}
																	</option>
																))}
															</select>
														</div>
													</div>
												</div>

												<div className="flex flex-col md:flex-row md:justify-between lg:flex-col xl:flex-row md:mb-12 flex-wrap">
													<div className="flex items-center w-full md:w-[25%] lg:w-full xl:w-[25%] justify-start mb-8 md:mb-0 lg:mb-8 xl:mb-0">
														<span className="svg-icon svg-2x mr-5">
															<SVGComponent url="/assets/wallet.svg" />
														</span>
														<p className="text-xl">
															{optionSelected.payments_quantity}{" "}
															<FormattedPlural
																value={optionSelected.payments_quantity}
																one={intl.formatMessage({
																	id: "APP.CREDIT.CALCULATOR.QUANTITY.LABEL",
																})}
																other={intl.formatMessage({
																	id: "APP.CREDIT.CALCULATOR.QUANTITIES.LABEL",
																})}
															/>{" "}
														</p>
													</div>
													<div className="flex items-center w-full md:w-[70%] lg:w-full xl:w-[70%] justify-start md:justify-end lg:justify-start xl:justify-end mb-8 md:mb-0">
														<span className="svg-icon svg-2x mr-5">
															<SVGComponent url="/assets/price_tag.svg" />
														</span>
														<div className="text-xl mr-2 flex flex-wrap">
															<p className="mr-2">Valor cuota:</p>
															<p>{currency} {setCurrencyFormat(fee)}</p>
														</div>
													</div>
												</div>
												<div className="flex flex-col md:flex-row md:justify-between">
													{/* <div className="flex items-center w-full">
														<button
															className="bg-primary text-white outline-none text-center w-full p-3 rounded-3xl text-2xl"
															type="submit"
														>
															Solicitar
														</button>
													</div> */}
												</div>
											</div>
										) : (
											<div className="bg-white w-[95%] m-auto py-7 px-6 rounded-bl-2xl rounded-br-2xl shadow-xl">
												<ErrorMessage
													image="empty.svg"
													descriptionKey="APP.GENERAL.ERROR.EMPTY.DATA"
												/>
											</div>
										)}
									</>
								)}
							</>
						)}
					</form>
				</div>
			</div>
		</section>
	);
};
