import { useIntl } from "react-intl";

export const ErrorMessage = ({ image, descriptionKey }) => {
	const intl = useIntl();

	return (
		<div className="flex flex-col justify-center items-center">
			<img
				src={`${process.env.PUBLIC_URL}/assets/${image}`}
				alt={intl.formatMessage({ id: "APP.GENERAL.ERROR" })}
				className="w-full md:w-1/2 xl:w-1/4 mb-8"
			/>
			<p className="text-text-content text-center font-semibold text-base">
				{intl.formatMessage({ id: descriptionKey })}
			</p>
		</div>
	);
};

// warning.svg
// "APP.GENERAL.ERROR.DESCRIPTION"