import { useEffect, useRef, useState } from "react";

export const useFetch = (url, countryData) => {
	const isMounted = useRef(true);
	const [state, setState] = useState({
		data: {},
		loading: true,
		error: null,
	});

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		setState({ data: null, loading: true, error: null });
		if(!countryData.code) return
    const data = { _country: countryData.code }

		fetch(url, { method: "POST", body: JSON.stringify(data), headers:{
      'Content-Type': 'application/json'
    } })
			.then((resp) => resp.json())
			.then((data) => {
				if (isMounted.current) {
					setState({
						loading: false,
						error: null,
						data: data.payload,
					});
				}
			})
			.catch((e) => {
        console.log({ e });
				setState({ data: {}, loading: false, error: true });
			});
	}, [url, countryData.code]);

	return state;
};
