import { FormattedMessage, useIntl } from 'react-intl'

export const WhereCanIPay = () => {
  const intl = useIntl();

  return (
    <section>
      <h2 className="text-center  text-4xl leading-10 md:text-5xl md:leading-20 font-black mb-12 pt-16">
        <FormattedMessage id="APP.WHERE.CAN.I.PAY.SECTION.TITLE" />
      </h2>
      <p className="text-center">
        <FormattedMessage id="APP.WHERE.CAN.I.PAY.SECTION.DESCRIPTION.2" />
      </p>
      <div  className="flex w-full flex-col items-center md:flex-row  place-content-center mb-4">

      <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "APP.WHERE.CAN.I.PAY.SECTION.DESCRIPTION.1" }) }} className="md:w-[50%] w-[85%] mb-6 text-center "></p>
      </div>

      <div className="flex flex-col-reverse md:flex-row flex-wrap w-full md:w-[90%] justify-start">
        <div className="w-full md:w-[60%] mb-10 md:mb-0">

        </div>
        <div className="w-full flex justify-center ">
          <img
            src={`${process.env.PUBLIC_URL}/assets/undraw_my_current_location_re_whmt.svg`}
            alt={intl.formatMessage({
              id: "APP.HOW.WORKS.SECTION.STEP.TWO.IMAGE.ALT",
            })}
            className="md:w-[30%]  w-100 mb-10 md:mb-0 object-contain "
          />
        </div>
      </div>
    </section>
  )
}
