import { FormattedMessage } from "react-intl";
import { useInView } from "react-intersection-observer";

import { Accordion } from "components/Accordion/Accordion";
import { Spinner } from "components/spinner/Spinner";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";

export const Fq = ({ faqs, isLoading, error }) => {
	const { ref, inView } = useInView();

	return (
		<section>
			<div className="hidden w-full lg:block lg:w-4/12 absolute">
				<img
					ref={ref}
					src={`${process.env.PUBLIC_URL}/assets/qas_phone.png`}
					alt="Mobile app loader screenshot"
					className={` z-0 mx-auto w-[90%] md:w-[50%] lg:w-[80%] xl:w-[90%] 2xl:w-[80%] ${inView ? "animate__animated animate__fadeInRight" : ""
						}`}
				/>
			</div>


			<div className="flex flex-col lg:flex-col items-center z-40 md:max-h-[35rem] min-h-[46rem]">

				<div className="w-full mb-8 lg:mb-0 lg:w-6/12 pl-10 md:pl-20 pr-14 pt-12 shadow-2xl rounded-3xl z-40 bg-white overflow-scroll overflow-x-hidden	 max-h-full">
					<h2 className="text-4xl leading-10 md:text-5xl md:leading-20 font-black mb-5 text-center md:pb-12">
						<FormattedMessage id="APP.NAV.FQ.LABEL" />
					</h2>
					{isLoading ? (
						<div className="flex justify-center items-center">
							<Spinner />
						</div>
					) : (
						<>
							{error ? (
								<ErrorMessage
									image="warning.svg"
									descriptionKey="APP.GENERAL.ERROR.DESCRIPTION"
								/>
							) : faqs.length > 0 ? (
								<Accordion faqs={faqs} />
							) : (
								<ErrorMessage
									image="empty.svg"
									descriptionKey="APP.GENERAL.ERROR.EMPTY.DATA"
								/>
							)}
						</>
					)}

				</div>

			</div>
		</section>
	);
};
